import React from 'react'

const Confetti = () => (
  <div className="confetti">
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
  </div>
)

export default Confetti